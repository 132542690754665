import React, { useEffect, useState, useCallback } from "react";
import { Form } from "react-bootstrap";
import { map } from "lodash";
import {
  checkhasPerutDivuach,
  checkTextAreaReportContent,
  getClockApiMaakavIn,
  getClockApiMaakavOut,
  getClockApiMaakavStatus,
} from "./clockFunctions";
import { getTypeRecordsList } from "./editClockFunctions";

import loader from "../../img/preLoader.gif";
import note from "../../img/icons/clockPage/note.svg";
import icoReports from "../../img/icons/clockPage/icoReports.svg";

import ReactStopwatch from "react-stopwatch";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { toast } from "react-toastify";
import moment from "moment-timezone";

const StartStopPage = ({ setSelectedPage }) => {
  const [loading, setLoading] = useState(false);
  const [reportTypeList, setReportsTypesList] = useState(null);
  const [selectedReportType, setSelectedReportType] = useState("");
  const [textAreaReport, setTextAreaReport] = useState("");
  const [percentWorked, setPercentWorked] = useState(0);
  const [{ hours, minutes, seconds }, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [maakavStatus, setMaakavStatus] = useState(null);
  const [showSelectError, setShowSelectError] = useState(false);
  const [lastExit, setLastExit] = useState(null);
  const [showPulse, setShowPulse] = useState(false);
  const [userTimeZone, setUserTimeZone] = useState(moment.tz.guess());

  const userJ = JSON.parse(localStorage.getItem("userData"));

  const convertToIsraelTime = (localTime) => {
    return moment(localTime).tz("Asia/Jerusalem").format("HH:mm:ss");
  };

  const convertToLocalTime = (israelTime) => {
    return moment.tz(israelTime, "HH:mm:ss", "Asia/Jerusalem").tz(userTimeZone).format("HH:mm:ss");
  };

  useEffect(() => {
    if (!loading && !reportTypeList) {
      setReportsTypesList({ data: { Items: getTypeRecordsList() } });
    }
  }, [loading, reportTypeList]);

  const loadMaakavStatus = useCallback(() => {
    const sendObj = { IDNumber: userJ.IDNO, SessionKey: userJ.SessionKey };
    getClockApiMaakavStatus(
      "v2/volunteer/MaakavStatus",
      sendObj,
      setLoading,
      (status) => {
        if (status.time) {
          status.localTime = convertToLocalTime(status.time);
        }
        setMaakavStatus(status);
      },
      setSelectedReportType,
      setTextAreaReport
    );
  }, [userJ.IDNO, userJ.SessionKey, userTimeZone]);

  useEffect(() => {
    loadMaakavStatus();
  }, [loadMaakavStatus]);

  useEffect(() => {
    if (maakavStatus?.timeAllValues?.hours) {
      const now = moment();
      const initialDate = moment().set({
        hour: maakavStatus.timeAllValues.hours,
        minute: maakavStatus.timeAllValues.minutes,
        second: 0,
      });
      const duration = moment.duration(now.diff(initialDate));

      setTime({
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds() || 1,
      });

      const percentWorked = Math.min(Math.max((duration.asHours() * 100) / 8, 5), 100);
      setPercentWorked(percentWorked);
    }
  }, [maakavStatus]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (maakavStatus?.timeAllValues?.hours) {
        const now = moment();
        const initialDate = moment().set({
          hour: maakavStatus.timeAllValues.hours,
          minute: maakavStatus.timeAllValues.minutes,
          second: 0,
        });
        const duration = moment.duration(now.diff(initialDate));
        const percentWorked = Math.min(Math.max((duration.asHours() * 100) / 8, 5), 100);
        setPercentWorked(percentWorked);
      }
    }, 60 * 1000 * 61);

    return () => clearInterval(interval);
  }, [maakavStatus]);

  const startHandler = () => {
    if (!selectedReportType) {
      setShowSelectError(true);
      toast.error("יש לבחור סוג דיווח", { toastId: "selectedReportType" });
      return;
    }
    
    if (checkhasPerutDivuach(selectedReportType, textAreaReport) && !textAreaReport) {
      setShowSelectError(true);
      toast.error("יש למלא פירוט דיווח", { toastId: "checkhasPerutDivuach" });
      return;
    }
    
    if (!checkTextAreaReportContent(selectedReportType, textAreaReport)) {
      return;
    }
    
    setShowSelectError(false);

    if (maakavStatus?.api?.IsCurrIn) {
      toast.error("יש לעשות יציאה קודם", { toastId: "MaakavStatus" });
      return;
    }
    
    const localTime = moment().format("HH:mm:ss");
    const israelTime = convertToIsraelTime(localTime);
    
    const sendObj = {
      IDNumber: userJ.IDNO,
      SessionKey: userJ.SessionKey,
      Typ: selectedReportType,
      MoreInfo: textAreaReport || '',
      Time: israelTime
    };

    getClockApiMaakavIn(
      "v2/volunteer/Maakavin",
      sendObj,
      setLoading,
      (status) => {
        status.localTime = localTime;
        setMaakavStatus(status);
      },
      setSelectedReportType,
      setTextAreaReport
    );
    
    toast.success(`דיווח כניסה התקבל: ${localTime} (זמן מקומי)`);
  };

  const stopHandler = () => {
    if (maakavStatus?.api?.IsCurrIn) {
      const localTime = moment().format("HH:mm:ss");
      const israelTime = convertToIsraelTime(localTime);
      
      const sendObj = { 
        IDNumber: userJ.IDNO, 
        SessionKey: userJ.SessionKey,
        Time: israelTime
      };

      getClockApiMaakavOut(
        "v2/volunteer/MaakavOut",
        sendObj,
        setLoading,
        (status) => {
          status.localTime = localTime;
          setMaakavStatus(status);
        },
        setSelectedReportType,
        setTextAreaReport,
        setPercentWorked,
        (exitTime) => setLastExit(convertToLocalTime(exitTime))
      );
      
      toast.success(`דיווח יציאה התקבל: ${localTime} (זמן מקומי)`);
    } else {
      setShowPulse("animate__animated animate__tada animate__repeat-2");
      setTimeout(() => setShowPulse(false), 4000);
      toast.error("יש לעשות כניסה קודם או לפתוח דיווח ידני");
    }
  };

  useEffect(() => {
    setTextAreaReport(
      selectedReportType === '2' ? 'הכשרת שרות לאומי' :
      selectedReportType === '3' ? 'נוכחות מחוץ למקום השרות' :
      ''
    );
  }, [selectedReportType]);

  return (
    <div className="StartStopPage animate__animated animate__fadeIn">
      <header className="clear">
        <h1>נוכחות</h1>
      </header>

      <img
        src={loader}
        alt="loader"
        className={`loader ${loading ? "active animate__animated animate__fadeIn" : ""}`}
      />

      {reportTypeList?.data?.Items && (
        <div className="selectCont">
          <img src={note} alt="note" />

          <Form.Control
            as="select"
            className="chooseReportSelect"
            isInvalid={showSelectError && !selectedReportType}
            disabled={maakavStatus?.api?.IsCurrIn}
            onChange={(e) => setSelectedReportType(e.target.value)}
            value={selectedReportType}
          >
            <option value="">סוג דיווח</option>
            {map(reportTypeList.data.Items, (item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </Form.Control>
        </div>
      )}

      {checkhasPerutDivuach(selectedReportType, textAreaReport) && (
        <div className="textAreaCont">
          <Form.Control
            as="textarea"
            className="textAreaReport"
            disabled={maakavStatus?.api?.IsCurrIn}
            isInvalid={showSelectError && checkhasPerutDivuach(selectedReportType, textAreaReport) && !textAreaReport}
            onChange={(e) => setTextAreaReport(e.target.value)}
            value={textAreaReport && textAreaReport !== "False" ? textAreaReport : ""}
            placeholder="פירוט דיווח"
          />
        </div>
      )}

      <div className={`buttonsDown ${maakavStatus?.api?.IsCurrIn ? "isIn" : "isOut"}`}>
        <button
          className={`start ${selectedReportType ? "animate__animated animate__bounceIn" : "disabled"}`}
          onClick={startHandler}
        >
          <span>כניסה</span>
        </button>
        <button className="stop" onClick={stopHandler}>
          <span>יציאה</span>
        </button>
      </div>

      <button
        className={`openReportPage ${showPulse}`}
        onClick={() => setSelectedPage("reportsPage")}
      >
        <img src={icoReports} alt="icoReports" />
        <span>דיווחי נוכחות</span>
      </button>

      <div className={`playStopBtn ${!selectedReportType && !maakavStatus?.api?.IsCurrIn && !lastExit ? "disabled" : ""}`}>
        <div className="buttonCont stopBtn">
          {maakavStatus?.api?.IsCurrIn ? (
            <>
              {(minutes || hours || seconds) && (
                <ReactStopwatch
                  seconds={seconds}
                  minutes={minutes}
                  hours={hours}
                  render={({ formatted }) => (
                    <div className="clock">
                      <div className="clockNumbers">{formatted}</div>
                      <p>
                        {moment().format("dddd")}, {moment().format("DD/MM/YY")}
                      </p>
                      <div className="showInTime">
                        <h3>התקבלה כניסה בשעה</h3>
                        <span>{maakavStatus.localTime} (זמן מקומי)</span>
                        <p>{maakavStatus.time} (שעון ישראל)</p>
                      </div>
                    </div>
                  )}
                />
              )}

              <CircularProgressbar
                value={percentWorked}
                text=""
                strokeWidth={4}
                styles={buildStyles({
                  pathTransitionDuration: 0.5,
                  pathColor: `rgba(27, 213, 145, ${percentWorked / 100})`,
                  textColor: "#f88",
                  trailColor: "white",
                  backgroundColor: "#3e98c7",
                })}
              />
            </>
          ) : (
            <div className="clock">
              <div className="clockNumbers">00:00:00</div>
              <p>
                {moment().format("dddd")} {moment().format("DD/MM/YY")}
              </p>
              {lastExit && (
                <div className="lastExit">
                  <p>יציאה אחרונה ב-</p>
                  <strong>{lastExit} (זמן מקומי)</strong>
                  <p>{convertToIsraelTime(lastExit)} (שעון ישראל)</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      
      {userTimeZone !== "Asia/Jerusalem" && (
        <div className="timeZoneInfo">
          <p>הדיווחים מתבצעים לפי הזמן המקומי שלך, ומומרים אוטומטית לשעון ישראל במערכת.</p>
        </div>
      )}
    </div>
  );
};

export default StartStopPage;
