import { RestUrls } from "../../Components/-Helpers-/config";
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from "moment-timezone";
import validator from "validator";

const api = axios.create({
  baseURL: RestUrls.sherutLeumiApi,
  headers: {'Content-Type': 'application/json'}
});

const handleApiError = (error, setLoading, setResponseData) => {
  setLoading(false);
  setResponseData({});
  toast.error('שגיאה', { toastId: 'error' });
  console.error(error);
};

export const getFromApiSherutLeumi = async (url, sendObj, setLoading, setResponseData) => {
  try {
    setLoading(true);
    const response = await api.post(url, sendObj);
    setResponseData(response);
  } catch (error) {
    handleApiError(error, setLoading, setResponseData);
  } finally {
    setLoading(false);
  }
};

export const getClockApiMaakavStatus = async (url, sendObj, setLoading, setResponseData, setSelectedReportType, setTextAreaReport) => {
  try {
    setLoading(true);
    const { data } = await api.post(url, sendObj);

    if (data?.Result !== 'Success') {
      throw new Error('שגיאה בקריאת סטטוס');
    }

    setSelectedReportType(data.IsCurrIn ? data.Typ : false);
    setTextAreaReport(data.IsCurrIn ? data.MoreInfo : false);

    if (data.IsCurrIn && data.TimeF) {
      const [hours, minutes] = [data.TimeF.substring(0, 2), data.TimeF.substring(2, 4)];
      const timeValue = `${hours}:${minutes}`;
      const timeAllValues = { hours: parseInt(hours), minutes: parseInt(minutes) };

      setResponseData({
        api: data,
        time: timeValue,
        timeAllValues,
        weekday: moment().format('dddd'),
        day: moment().format('DD/MM/YY'),
      });
    }
  } catch (error) {
    handleApiError(error, setLoading, setResponseData);
  } finally {
    setLoading(false);
  }
};

export const getClockApiMaakavOut = async (url, sendObj, setLoading, setResponseData, setSelectedReportType, setTextAreaReport, setPercentWorked, setLastExit = false) => {
  try {
    setLoading(true);
    const { data } = await api.post(url, sendObj);

    if (data?.Result !== 'Success') {
      throw new Error('שגיאה');
    }

    setResponseData(false);
    setSelectedReportType(false);
    setTextAreaReport(false);
    setPercentWorked(false);
    toast.success('היציאה התקבלה בהצלחה');

    if (setLastExit) {
      setLastExit(moment().format("HH:mm"));
    }
  } catch (error) {
    handleApiError(error, setLoading, setResponseData);
  } finally {
    setLoading(false);
  }
};

export const getClockApiMaakavIn = async (url, sendObj, setLoading, setResponseData, setSelectedReportType, setTextAreaReport) => {
  try {
    setLoading(true);
    const { data } = await api.post(url, sendObj);

    if (data?.Result !== 'Success') {
      throw new Error(data?.ErrorMessage === 'No maakav record' ? 'שגיאה. לא קיים מעקב.' : 'שגיאה');
    }

    toast.success('הכניסה התקבלה בהצלחה');
    const userJ = JSON.parse(localStorage.getItem("userData"));
    const statusObj = { IDNumber: userJ.IDNO, SessionKey: userJ.SessionKey };

    await getClockApiMaakavStatus('v2/volunteer/MaakavStatus', statusObj, setLoading, setResponseData, setSelectedReportType, setTextAreaReport);
  } catch (error) {
    handleApiError(error, setLoading, setResponseData);
  } finally {
    setLoading(false);
  }
};

export const showCurrentMonthName = (monthNumber, currentYear, action = false) => {
  const date = moment(`${currentYear}-${monthNumber}-01`);
  const format = action === 'monthNameOnly' ? 'MMMM' : 'MMMM YYYY';
  return date.isValid() ? date.format(format) : '---';
};

export const checkShowDays = (monthData, item) => {
  const now = moment();
  const itemDate = moment(`${monthData.Year}-${monthData.Month}-${item.dayData.DOM}`);
  return !itemDate.isAfter(now, 'day');
};

export const changeDay = (action, currentMonth, setCurrentMonth, currentYear, setCurrentYear, monthData = false) => {
  const now = moment();
  const current = moment(`${currentYear}-${currentMonth}-01`);
  const flagMore = current.isBefore(now, 'month');

  switch (action) {
    case 'checkActive':
      return !flagMore;
    case 'checkEditMode':
      return !monthData.ReportConfirmationDate;
    case 'back':
      current.subtract(1, 'month');
      setCurrentMonth(current.month() + 1);
      setCurrentYear(current.year());
      break;
    case 'forward':
      if (flagMore) {
        current.add(1, 'month');
        setCurrentMonth(current.month() + 1);
        setCurrentYear(current.year());
      }
      break;
  }
};

export const checkhasPerutDivuach = (selectedReportType, textAreaReport) => 
  ['2', '3'].includes(selectedReportType) || (textAreaReport && textAreaReport !== 'False');

export const checkTextAreaReportContent = (selectedReportType, textAreaReport) => {
  if (checkhasPerutDivuach(selectedReportType, textAreaReport)) {
    if (validator.isNumeric(textAreaReport)) {
      toast.error('יש לרשום גם מלל');
      return false;
    }
    if (/^[A-Za-z0-9]*$/.test(textAreaReport)) {
      toast.error('אסור מילוי רק תווים באנגלית');
      return false;
    }
  }
  return true;
};

export const formatLocalTime = (serverTime, timeZoneOffset) => {
  if (!serverTime) return '';
  return moment.tz(`2023-01-01 ${serverTime}`, "Asia/Jerusalem")
    .add(timeZoneOffset, 'minutes')
    .format('HH:mm');
};

export const displayTime = (serverTime, timeZoneOffset) => {
  const localTime = formatLocalTime(serverTime, timeZoneOffset);
  return `${localTime} (מקומי) / ${serverTime} (ישראל)`;
};